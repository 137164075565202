<template>
  <div>
    <div class="title">
      <el-breadcrumb separator="/">
        <el-breadcrumb-item :to="{ path: 'assignment' }">任务</el-breadcrumb-item>
        <el-breadcrumb-item>新建任务</el-breadcrumb-item>
      </el-breadcrumb>
    </div>
    <div class="content">
      <div style="display:flex">
        <div class="left">
          <div class="item">
            <div class="title">任务名称</div>
            <el-input v-model="createTask.name" placeholder="请输入任务名称" style="width:369px"></el-input>
          </div>
          <div class="item">
            <div class="title">选择日期</div>
            <el-date-picker type="dates" style="width:369px" v-model="date_stamps" placeholder="选择一个或多个日期" value-format="timestamp">
            </el-date-picker>
          </div>
          <div class="item">
            <div class="title">开始时间</div>
            <el-time-picker style="width:369px" v-model="start_minute" placeholder="选择开始时间" format="HH:mm" value-format="HH:mm">
            </el-time-picker>
          </div>
          <div class="item">
            <div class="title">结束时间</div>
            <el-select v-model="value" placeholder="请选择" style="width:80px;margin-right:10px">
              <el-option label="当日" value="0" style="width:80px">
              </el-option>
              <el-option label="次日" value="1" style="width:80px">
              </el-option>
            </el-select>
            <el-time-picker style="width:279px" v-model="end_minute" placeholder="选择结束时间" format="HH:mm" value-format="HH:mm">
            </el-time-picker>
          </div>
          <div class="item">
            <div class="title">人员</div>
            <el-select style="width:369px" v-model="createTask.patrol_user_ids" multiple placeholder="请选择">
              <el-option v-for="item in memberList" :key="item.id" :label="item.name" :value="item.user_id">
              </el-option>
            </el-select>
          </div>

          <div class="item">
            <div class="title">次数</div>
            <el-input-number v-model="createTask.count" controls-position="right" :min="1" :max="10"></el-input-number>
            </el-input>
          </div>
          <div class="item">
            <div class="title">严格按照点位巡检</div>
            <el-switch v-model="createTask.in_order" active-color="#77b2f9" inactive-color="#eff1f3" :active-value="1" :inactive-value="0">
            </el-switch>
            </el-input>
          </div>
          <!-- <div class="item">
            <div class="title">自动上传定位</div>
            <el-switch v-model="createTask.position" active-color="#77b2f9" inactive-color="#eff1f3" :active-value="1" :inactive-value="0">
            </el-switch>
            </el-input>
          </div> -->
          <div class="item" style="margin-bottom:30px">
            <div class="title">备注</div>
            <el-input type="textarea" rows='3' placeholder="请输入内容" v-model="createTask.remark" style="width:369px;" show-word-limit>
            </el-input>
          </div>
          <div class="item" style="margin-left:80px;width:500px">
            <el-upload :action="postUrl" list-type="picture-card" :auto-upload="true" :limit="9" :before-upload="beforeAvatarUpload" :data='postData' :file-list="fileList" :on-success="handleSuccess">
              <i slot="default" class="el-icon-plus"></i>
              <div slot="file" slot-scope="{file}">
                <img class="el-upload-list__item-thumbnail" :src="file.url" alt="">
                <span class="el-upload-list__item-actions">
                  <span class="el-upload-list__item-preview" @click="handlePictureCardPreview(file)">
                    <i class="el-icon-zoom-in"></i>
                  </span>
                  <span v-if="!disabled" class="el-upload-list__item-delete" @click="handleRemove(file)">
                    <i class="el-icon-delete"></i>
                  </span>
                </span>
              </div>
            </el-upload>
            <el-dialog :visible.sync="dialogVisible">
              <img width="100%" :src="dialogImageUrl" alt="">
            </el-dialog>
          </div>
        </div>
        <div class="right">
          <el-button type="primary" plain @click="choiceCycle" style="margin-top:10px">添加点位</el-button>
          <div style="height:50px;line-height:50px"><i class="el-icon-warning-outline"></i> 通过拖拽节点可以选择排序</div>
          <el-tree :data="dataArr" node-key="id" draggable :allow-drop="allowDrop" class="nodeTree">
            <div class="custom-tree-node" slot-scope="{ node, data }">
              <span style="display:inline-block;width:155px">
                <vIcon type="md-menu" /> {{ node.label }}</span>
              <vIcon type="md-close" @click="editDataArr(node.data)" />
            </div>
          </el-tree>
        </div>
      </div>
      <div style="margin:50px auto;width:300px;padding-bottom:50px">
        <el-button type="primary" plain style="margin-right:50px" @click="goLast">取消</el-button>
        <el-button type="primary" @click="createTaskEvent">确定</el-button>
      </div>
    </div>
    <div>
    </div>
    <el-dialog title="选择点位" :visible.sync="choiceCycleshow" width="30%">
      <el-tree :data="siteList" show-checkbox node-key="id" ref="tree" highlight-current :props="defaultProps" @check="checkNode">
      </el-tree>
      <span slot="footer" class="dialog-footer">
        <el-button @click="closeDialogVisible">取 消</el-button>
        <el-button type="primary" @click="queryDialogVisible">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { organization, site, files, problem, task, obsUrl } from '../../utils/api';
import qs from 'qs';
export default {
  name:'addTask',
  data() {
    return {
      createTask: {
        name: '',
        site_ids: [],
        date_stamps: [],
        start_minute: '',
        end_minute: '',
        count: 1,
        patrol_user_ids: [],
        in_order: 1,
        position: 0,
        files: [],
        remark: ''
      },
      value: '0',
      date_stamps: [],
      start_minute: '',
      end_minute: '',
      // 人员列表
      memberList: [],
      postUrl: obsUrl,
      postData: {},
      dialogImageUrl: '',
      dialogVisible: false,
      disabled: false,
      fileListMain: [],
      fileList: [],
      imgUrl: '',
      // 点位列表
      siteList: [],
      checkAll: false,
      isIndeterminate: false,
      choiceCycleshow: false,
      checkedSiteList: [],
      siteListArr: [],
      dataArr: [],
      defaultProps: {
        children: 'sites',
        label: 'name'
      },
      checkedKeys: []
    };
  },

  components: {},

  computed: {},

  methods: {
    async getLIstMember() {
      const res = await this.$http.get(organization.listMember);
      this.memberList = res.data;
    },
    handlePictureCardPreview(file) {
      this.dialogImageUrl = file.url;
      this.dialogVisible = true;
    },
    async beforeAvatarUpload(file) {
      const isJPG = file.type === 'image/jpeg' || file.type === 'image/png';
      const isLt2M = file.size / 1024 / 1024 < 2;

      if (!isJPG) {
        return this.$message.error('上传图片只能是 JPG 或 PNG 格式!');
      }
      let par = {
        content_type: file.type,
        module: 'task'
      };
      const res = await this.$http.get(
        files.huaweiCloudObsPolicySignature + '?' + qs.stringify(par)
      );
      this.postData = res.data;
      this.imgUrl = this.postUrl + '/' + res.data.key;
      if (!isLt2M) {
        this.$message.error('上传图片大小不能超过 2MB!');
      }
      return isJPG && isLt2M;
    },
    handleRemove(file) {
      let find = this.fileList.findIndex(item => {
        return item.uid == file.uid;
      });
      this.fileList.splice(find, 1);
    },
    handleSuccess(res, file, fileList) {
      file.imgUrl = this.imgUrl;
      this.fileList = fileList;
    },
    goLast() {
      this.$router.go(-1);
    },
    // 获取点位列表
    async getSiteLIst() {
      const res = await this.$http.get(site.listGroupSite);
      this.siteList = res.data.filter(item => {
        return item.sites.length > 0;
      });
    },
    choiceCycle() {
      let arr = [];
      this.dataArr.forEach(item => {
        arr.push(item.id);
      });
      if (this.$refs.tree) {
        this.$refs.tree.setCheckedKeys([]);
        this.$refs.tree.setCheckedKeys(arr);
      }
      this.choiceCycleshow = true;
    },
    queryDialogVisible() {
      this.dataArr = [];
      this.checkedKeys.forEach(citem => {
        this.siteList.forEach(item => {
          item.sites.forEach(sitem => {
            if (citem == sitem.id) {
              this.dataArr.push({ label: sitem.name, id: sitem.id });
            }
          });
        });
      });
      this.choiceCycleshow = false;
      this.$nextTick(function() {
        document.getElementsByClassName('el-tree__empty-text')[0] &&
          (document.getElementsByClassName('el-tree__empty-text')[0].innerHTML =
            '');
      });
    },
    closeDialogVisible() {
      this.choiceCycleshow = false;
    },
    // 拖拽函数
    allowDrop(draggingNode, dropNode, type) {
      return type !== 'inner';
    },
    // 创建一个新的任务
    async createTaskEvent() {
      let dateTime = new Date(new Date().toLocaleDateString()).getTime();
      this.createTask.site_ids = [];
      this.dataArr.forEach(item => {
        this.createTask.site_ids.push(item.id);
      });
      if (!this.createTask.name) {
        return this.$message.error('请填写任务名称');
      }
      if (this.createTask.site_ids.length === 0) {
        return this.$message.error('请选择点位');
      }
      if (this.date_stamps.length === 0) {
        return this.$message.error('请选择日期');
      }
      if (this.createTask.patrol_user_ids.length == 0) {
        return this.$message.error('请选择巡检人员');
      }
      let isGUoqi = this.date_stamps.some(item => {
        return item < dateTime;
      });
      if (isGUoqi) {
        return this.$message.error('所选时间已过期，请重新选择');
      }
      this.createTask.date_stamps = JSON.parse(
        JSON.stringify(this.date_stamps)
      );
      this.createTask.date_stamps = this.createTask.date_stamps.map(item => {
        return item / 1000;
      });
      if (!this.start_minute) {
        return this.$message.error('请选择任务开始时间');
      }
      if (!this.end_minute) {
        return this.$message.error('请选择任务结束时间');
      }
      this.createTask.start_minute =
        this.start_minute.split(':')[0] * 60 -
        0 +
        (this.start_minute.split(':')[1] - 0);
      this.createTask.end_minute =
        this.end_minute.split(':')[0] * 60 -
        0 +
        (this.end_minute.split(':')[1] - 0);
      this.createTask.files = [];
      this.fileList.forEach(item => {
        this.createTask.files.push(item.imgUrl);
      });
      if (this.value == 1) {
        this.createTask.end_minute += 1440;
      }
      if (this.createTask.start_minute >= this.createTask.end_minute) {
        return this.$message.error('任务开始时间必须小于结束时间');
      }
      const res = await this.$http.post(task.createTask, this.createTask);
      if (res.code == 0) {
        this.$message.success('创建任务成功');
        // this.createTask = JSON.parse(JSON.stringify(this.clonecreateTask));
        // this.fileList.splice(0, this.fileList.length);
        // this.$refs.tree.setCheckedKeys([]);
        // this.dataArr = [];
        // this.date_stamps = [];
        // this.start_minute = '';
        // this.end_minute = '';
        // this.value='0'
        // this.$nextTick(function() {
        //   document.getElementsByClassName('el-tree__empty-text')[0].innerHTML =
        //     '';
        // });
        this.$router.push({
          path: '/assignment',
          query: { date_stamps: this.createTask.date_stamps }
        });
      }
    },
    checkNode(data, node) {
      this.checkedKeys = node.checkedKeys;
    },
    editDataArr(data) {
      this.dataArr = this.dataArr.filter(item => {
        return item.id != data.id;
      });
      this.$nextTick(function() {
        if (document.getElementsByClassName('el-tree__empty-text')[0]) {
          document.getElementsByClassName('el-tree__empty-text')[0].innerHTML =
            '';
        }
      });
    }
  },
  created() {
    this.clonecreateTask = JSON.parse(JSON.stringify(this.createTask));
    this.getLIstMember();
    this.getSiteLIst();
  },
  mounted() {
    if (document.getElementsByClassName('el-tree__empty-text')) {
      document.getElementsByClassName('el-tree__empty-text')[0].innerHTML = '';
    }
  }
};
</script>
<style lang='scss' scoped>
.title {
  width: 1168px;
  height: 56px;
  background: #ffffff;
  border-radius: 4px;
  display: flex;
  align-items: center;
  padding-left: 16px;
  margin-bottom: 8px;
}
.content {
  width: 1168px;
  min-height: 703px;
  background: #ffffff;
  border-radius: 4px;
  .item {
    display: flex;
    align-items: center;
    // margin-bottom: 24px;
    .title {
      width: 130px;
      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #606266;
      line-height: 14px;
    }
  }
  .right {
    margin-left: 150px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}
.custom-tree-node {
  // display: block;
  width: 190px;
  height: 30px !important;
  background: #ecf5ff;
  border-radius: 4px;
  border: 1px solid #a3d0fd;
  display: flex;
  align-items: center;
  box-sizing: border-box;
  padding-left: 10px;
  color: #0486fe;
  margin-left: -25px;
  min-height: 30px !important;
}

.nodeTree /deep/ div.el-tree-node__content {
  margin-bottom: 5px !important;
  height: 30px !important;
}
</style>
